* {
  box-sizing: border-box;
  /* font-family: Helvetica, Helvetica Neue, sans-serif; */
  font-family: Plus Jakarta Sans, sans-serif;
}

body {
  /* background-color: rgb(245, 247, 249); */
  margin: 0;
  user-select: none;
}
