.header {
  background-color: #1c57ad;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  // height: 100vh;
  z-index: 999;
  min-width: 108px;
}
.fullWidth {
  width: 227px;
  position: fixed;
}
.smallWidth {
  width: 108px;
}
.scrim {
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  // z-index: 8;
}
.headerContent {
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;
  height: inherit;
}
.block1,
.block2 {
  align-items: center;
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  padding: 20px;
  height: inherit;
}
// .block1 {
//   height: 125px;
// }
.headerIcon {
  margin: 10px;
  margin-top: 45px;
  margin-bottom: 70px;
}
.profileContainer {
  cursor: pointer;
  align-items: center;
  color: #ffffff;
  display: flex;
  mix-blend-mode: normal;
  position: absolute;
  bottom: 40px;
}
.profileName {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 15px;
  margin-left: 10px;
  opacity: 0.9;
}
.notification {
  position: relative;
}
.notificationBadge {
  align-items: center;
  background: #03bd5b;
  border: 2px solid #ffffff;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  height: 20px;
  justify-content: center;
  line-height: 14px;
  position: absolute;
  right: -9px;
  top: -12px;
  width: 20px;
}
.profileIcon {
  height: 23px;
  width: 23px;
}
.dropdownIcon {
  cursor: pointer;
  height: 13px;
  margin-left: 9px;
  width: 13px;
}
.navLinks {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.navLinksItems {
  display: flex;

  flex-direction: column;
  gap: 40px;
  width: 100%;
}
.navLinksItemsCenter {
  align-items: center;
}
.iconImg {
  margin-right: 5px;
}
.link {
  align-items: center;

  color: #b2cce5;
  display: flex;
  font-size: 14px;
  height: 39px;
  letter-spacing: 0.25px;
  line-height: 20px;
  // margin-right: 20px;
  padding: 0 20px 3px;
  font-weight: 600;
  position: relative;
  text-decoration: none;
  z-index: 1;
  width: 100%;

  &:hover,
  &:focus {
    color: #ffffff;
  }
}
.linkWithIcon {
  justify-content: center;
}
.activeLink {
  // opacity: 1;
  color: white;

  &::before {
    background: #ffffff;
    box-shadow: 0 2px 21px rgba(0, 0, 0, 0.22);
    border-radius: 0px 4px 4px 0px;
    content: " ";
    display: flex;
    height: 100%;
    left: 0;
    mix-blend-mode: normal;
    position: absolute;
    top: 0;
    width: 10px;
    z-index: -1;
  }
}
.dropdownContainer {
  position: relative;
  display: flex;
}
.dropdown {
  background: #3267b5;
  border-radius: 4px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  padding: 6px;
  position: absolute;
  // right: 0;
  // left: -15px;
  top: calc(100% + -270px);
  width: 187px;
  z-index: 12;
  color: white;

  .switchContainer {
    background: #1c57ad;
    border-radius: 26px;
    width: 100%;
    height: 38px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    margin: 5px 0px;

    .switch {
      width: 76px;
      flex-basis: 50%;

      border-radius: 22px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .switchActive {
      color: #1c57ad;
      background: #ffffff;
    }
  }
}
.fullDropdown {
  right: -15px;
}
.smallDropdown {
  left: 50px;
  top: calc(100% - 195px);
  background: #3267b5;
}
.logoutButton {
  background-color: transparent;
  border: none;
  border-radius: 6px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  height: 35px;
  line-height: 1;
  margin: 0;
  text-align: left;
  width: 100%;
  padding: 0px 15px;
  align-items: center;
  display: flex;
  &:hover {
    background-color: #103e81;
    color: #fff;
  }
}

.headerIconContainer {
  text-align: center;
}

.versionNumber {
  color: #ffffff;
  // text-align: right;
  padding: 0px 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.horizontalLine {
  width: 180px;
  margin: 25px 10px !important;
  background-color: white !important;
  height: 10px;
  position: absolute;
  bottom: 50px;
}
.versionContainer {
  position: absolute;
  bottom: 15px;
}
