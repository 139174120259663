.resolve-log-modal {
  .header {
    display: flex;
    font-weight: bold;
  }
  .profile-pic {
    width: 28px;
    height: 28px;
    margin-right: 10px;
  }
  .close-btn {
    color: red;
    text-align: end;
    cursor: pointer;
  }
  .heading {
    font-weight: 600;
    font-size: 17px;
    line-height: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
    user-select: text;
  }
  .table {
    .table-header {
      grid-template-columns: 1fr 2fr 2fr 100px 2fr;
      p {
        &:nth-child(5) {
          text-align: center;
          // padding-right: 14px;
        }
      }
    }
    .table-row {
      grid-template-columns: 1fr 2fr 2fr 100px 2fr;
    }
  }
}
