.settings-page {
  .mainHeading {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    text-decoration: underline;
    margin-bottom: 25px;
  }

  .systemSettingsContainer {
    margin: 5px 0px;
    margin-top: 40px;

    .margin-top-10 {
      margin-top: 10px;
    }
  }

  .rowDivider {
    border: 0.1px solid #eaeaea;
    // margin: 0 2rem;
    flex-basis: 100%;
    opacity: 0.5;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .name-text {
    // position: absolute;
    // width: 113px;
    height: 28px;
    left: 150px;
    top: 160px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    font-feature-settings: "liga" off;
    color: #1a242a;
    display: flex;
  }

  .form-box {
    padding: 24px 40px;
    margin: 0;
    gap: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    // position: absolute;
    width: 611px;
    left: 150px;
    top: 240px;
    background: #ffffff;
    border: 1px solid #bacbd5;
    border-radius: 4px;
    height: calc(100% - 67px);
  }

  .dotted-box {
    border: dashed 2px #bacbd5;
    box-sizing: border-box;
    width: 500px;
    height: 110px;
    border-style: dashed;

    .human {
      position: absolute;
      width: 65px;
      height: 65px;
      left: 62px;
      top: 44px;
    }

    .drag-text {
      position: absolute;
      width: 198px;
      height: 16px;
      left: 207px;
      top: 75px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      font-feature-settings: "liga" off;
      color: #abaeb0;
    }
    .arrow {
      position: absolute;
      left: 300px;
      right: 350px;
      top: 42px;
    }
  }

  .popup-footer {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;

    button {
      font-size: 14px;
    }

    .cancel-btn {
      margin-right: 25px;
      font-weight: 600;
    }

    .save-btn {
      border-radius: 4px;
      background-color: #1c57ad;
      width: 130px;
      height: 35px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      font-feature-settings: "liga" off;
      color: #ffffff;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }

  .small-text {
    // position: absolute;
    width: 330px;
    height: 26px;
    left: 150px;
    margin-bottom: 10px;
    margin-top: 5px;
    top: 200px;
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    font-feature-settings: "liga" off;
    color: #445f6f;
  }

  .change-password {
    width: 122px;
    height: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: "liga" off;
    color: #1c57ad;
    cursor: pointer;
  }

  .flex-text {
    // position: absolute;
    width: 100%;
    height: 28px;
    left: 793px;
    top: 160px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    font-feature-settings: "liga" off;
    color: #000000;
    display: flex;
  }

  .flex-box {
    padding: 30px 40px;
    margin: 0;
    gap: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    // position: absolute;
    // width: 600px;
    left: 793px;
    top: 240px;
    justify-content: center;
    // height: 200px;
    background: #ffffff;
    border: 1px solid #bacbd5;
    border-radius: 4px;
    flex-direction: row;

    .b1 {
      display: flex;
      flex-direction: column;
      // margin-right: 30px;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .b2 {
      display: flex;
      // margin-bottom: 36px;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .col-1 {
      display: flex;
      flex-direction: column;
      // align-items: center;
      margin-right: 30px;
    }

    .email {
      width: 119px;
      height: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      font-feature-settings: "liga" off;
      color: #1a242a;
      display: flex;
      // flex: none;
      order: 0;
      flex-grow: 0;
    }
    .commits {
      width: 160px;
      height: 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      font-feature-settings: "liga" off;
      color: #7697ab;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
    .sw {
      display: flex;
      flex-direction: co;
      align-items: center;
      justify-content: space-between;
    }
    .push {
      width: 119px;
      height: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      font-feature-settings: "liga" off;
      color: #1a242a;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }
  .flex-sub-text {
    // position: absolute;
    width: 400px;
    height: 26px;
    margin-bottom: 10px;
    margin-top: 5px;
    left: 793px;
    top: 200px;
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    font-feature-settings: "liga" off;
    color: #445f6f;
  }

  .rect-box {
    position: absolute;
    left: 793px;
    top: 500px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 10px;
    width: 600px;
    height: 62px;
    background: #f4f7fb;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;

    .box-head {
      width: 100%;
      height: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      justify-content: space-between;
      font-feature-settings: "liga" off;
      color: #1a242a;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }

  .inputLabel {
    // width: 74px;
    height: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: "liga" off;
    color: #1a242a;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .notification {
    margin-top: 2px;
  }

  .price-limit-container {
    // margin-top: 40px;
    display: flex;
    flex-direction: column;
    width: 613px;
    .price-flex-box {
      background: #ffffff;
      border: 1px solid #bacbd5;
      border-radius: 4px;
      flex-direction: row;
      padding: 20px;
      .b1 {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .b2 {
          // margin-bottom: 25px;
          width: 100%;
        }

        .save-btn {
          border-radius: 4px;
          background-color: #1c57ad;
          width: 130px;
          height: 35px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          font-feature-settings: "liga" off;
          color: #ffffff;
          flex: none;
          order: 0;
          flex-grow: 0;
          margin-left: 10px;
          margin-bottom: 2px;
        }
      }
    }

    input {
      width: 100% !important;
    }
  }

  .tagged-user-container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    .tagged-flex-box {
      padding: 20px;
      margin: 0;
      gap: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      // position: absolute;
      // width: 600px;
      left: 793px;
      top: 240px;
      justify-content: center;
      // height: 200px;
      background: #ffffff;
      border: 1px solid #bacbd5;
      border-radius: 4px;
      flex-direction: row;

      .b1 {
        width: 100%;

        .b2 {
          margin-bottom: 20px;
          max-width: 358px;
        }

        .save-btn {
          border-radius: 4px;
          background-color: #1c57ad;
          width: 130px;
          height: 35px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          font-feature-settings: "liga" off;
          color: #ffffff;
          flex: none;
          order: 0;
          flex-grow: 0;
        }
      }
    }
  }
  .manual-upload {
    border-radius: 4px;
    width: 613px;
    display: inline-block;
    padding: 30px 30px;
    margin: 35px 0px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #bacbd5;

    .b1 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .b2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .col-1 {
      display: flex;
      flex-direction: column;
      margin-right: 30px;
    }
  }
}

.settings-input-wrapper {
  .input-label {
    letter-spacing: 1.13px;
    padding: 2px;
    width: 67px;
    height: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: "liga" off;
    color: #1a242a;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .settings-input {
    input {
      width: 500px;
      border-radius: 5px;
      border: 1px solid #e0e7ff;
      background-color: rgba(246, 246, 246, 0.2);
      height: 39px;
      margin-top: 5px;
      margin-bottom: 15px;
      font-size: 15px;
      padding: 10px;

      &:focus {
        outline: none;
      }
    }
  }

  .price-limit-input {
    display: flex;
    align-items: center;
    border: 1px solid #e0e7ff;
    border-radius: 5px;
    height: 39px;
    margin-top: 5px;
    input {
      border: none;
      margin-bottom: 7px;
    }

    span {
      margin: 5px;
      font-weight: 600;
    }
  }
}
