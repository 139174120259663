.popup-wrapper {
  .popup-body {
    .upload-popup-row {
      margin-top: 20px;
      .template_btn {
        width: 100%;
        height: 36px;
        left: 1186px;
        top: 42px;
        color: white;

        /* BCG-Primary/Main */
        background: #1c57ad;
        border-radius: 8px;
      }
    }
  }
}
.upload-subheading {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #445f6f;
}
